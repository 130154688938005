var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"timetable-wrapper"},[_c('OrganismPageSubHeader',{attrs:{"title":_vm.siteName,"icon":"icons/icon-location-pin.svg"}},[_c('div',{staticClass:"time-container"},[_c('AtomSvgIcon',{attrs:{"imageSrc":"icons/icon-clock.svg","className":"sub-header-icon","height":"20"}}),_c('AtomText',{attrs:{"tag":"p","content":_vm.currentTime}})],1)]),_c('BContainer',[_c('BRow',[_c('BCol',[_c('AtomActionSelector',{staticClass:"tab-selector"},[_c('a',{class:{ active: this.showDepartures },on:{"click":function($event){return _vm.tabToggle(true)}}},[_vm._v(" "+_vm._s(_vm.$t('timetable.departure.selectorHeadline'))+" ")]),_c('a',{class:{ active: !this.showDepartures },on:{"click":function($event){return _vm.tabToggle(false)}}},[_vm._v(" "+_vm._s(_vm.$t('timetable.arrival.selectorHeadline'))+" ")])])],1)],1)],1),_c('BContainer',[_c('BRow',[(_vm.showDepartures)?_c('BCol',_vm._l((_vm.departures),function(day,index){return _c('MoleculeDateSection',{key:`departure-${index}`,ref:`date-${day.format('DD-MM-YYYY')}`,refInFor:true,attrs:{"active":_vm.selectedDate.isSame(day, 'day'),"date":day}},_vm._l((_vm.tripsPerDay(day)),function(departure){return _c('OrganismTimetableCard',{key:`departure-${departure.id}`,attrs:{"title":_vm.$t('timetable.departureTime'),"date":departure.date,"from":{ name: _vm.getSiteName, address: _vm.getSiteAddress },"to":{
              name: `${departure.location.zip}, ${departure.location.city}`,
              address: `${departure.location.zip}, ${departure.location.city}`,
            },"seats":departure.freeSeats,"isLookingForDriver":departure.role === _vm.TimetablesRolesEnum.PASSENGER,"showSeats":"","hasMap":false},on:{"click":function($event){return _vm.handleCardClick(departure)}}})}),1)}),1):_c('BCol',_vm._l((_vm.arrivals),function(day,index){return _c('MoleculeDateSection',{key:`arrivals-${index}`,ref:`date-${day.format('DD-MM-YYYY')}`,refInFor:true,attrs:{"active":_vm.selectedDate.isSame(day, 'day'),"date":day}},_vm._l((_vm.arrivalsPerDay(day)),function(arrival){return _c('OrganismTimetableCard',{key:`arrivals-${arrival.id}`,attrs:{"title":_vm.$t('timetable.arrivalTime'),"date":arrival.date,"from":{
              name: `${arrival.location.zip}, ${arrival.location.city}`,
              address: `${arrival.location.zip}, ${arrival.location.city}`,
            },"to":{ name: _vm.getSiteName, address: _vm.getSiteAddress },"seats":arrival.freeSeats,"isLookingForDriver":arrival.role === _vm.TimetablesRolesEnum.PASSENGER},on:{"click":function($event){return _vm.handleCardClick(arrival)}}})}),1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }