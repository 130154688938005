
import Vue from 'vue';
import i18n from '@/i18n';
import TemplateTimetableKiosk from '@/components/templates/timetable/TemplateTimetableKiosk.vue';
import { TimetableType } from '@/api/timetable/timetableApi';

export default Vue.extend({
  name: 'TimetableKiosk',
  components: { TemplateTimetableKiosk },
  metaInfo: {
    title: ` - ${i18n.t('tabs.kiosk')}`,
  },
  computed: {
    timetableType() {
      return this.$router.currentRoute.meta?.timetableType as TimetableType;
    },
  },
});
